/* eslint-disable no-unreachable */
import ImgCompo from "components/img-compo";
import React from "react";
import { Link } from "react-router-dom";

const BtnCompo = ({
  buttonStyle,
  buttonIcon = null,
  buttonText,
  buttonAction,
  buttonClass = "",
  buttonOutline = false,
  buttonIconHeight = 16,
  buttonIconWidth = 16,
  ...children
}) => {
  switch (buttonStyle) {
    case "big":
      return (
        <Link
          className={`btn  btn-lg ${buttonClass} ${
            buttonOutline ? "btn-outline-primary" : "btn-primary"
          }`}
          to={buttonAction}
          {...children}
        >
          {buttonText}
          {buttonIcon ? (
            <ImgCompo
              src={buttonIcon}
              alt={buttonText ? buttonText : "icon"}
              height={buttonIconHeight}
              width={buttonIconWidth}
            />
          ) : null}
        </Link>
      );
      break;

    case "small":
      return (
        <Link
          className={`btn  btn-sm ${buttonClass} ${
            buttonOutline ? "btn-outline-primary" : "btn-primary"
          }`}
          to={buttonAction}
          {...children}
        >
          {buttonText}
          {buttonIcon ? (
            <ImgCompo
              src={buttonIcon}
              alt={buttonText ? buttonText : "icon"}
              height={buttonIconHeight}
              width={buttonIconWidth}
            />
          ) : null}
        </Link>
      );

      break;

    default:
      return (
        <Link
          className={`btn  ${buttonClass} ${
            buttonOutline ? "btn-outline-primary" : "btn-primary"
          }`}
          to={buttonAction}
          {...children}
        >
          {buttonText}
          {buttonIcon ? (
            <ImgCompo
              src={buttonIcon}
              alt={buttonText ? buttonText : "icon"}
              height={buttonIconHeight}
              width={buttonIconWidth}
            />
          ) : null}
        </Link>
      );
      break;
  }
};

export default BtnCompo;
