import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
// import ImgCompo from "components/img-compo";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const ExtendedFamilySelection = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined")
      localStorage.getItem("indexPage")
        ? setIndex(parseInt(localStorage.getItem("indexPage")))
        : setIndex(0);
  }, [index]);

  return (
    <Container className="h-100">
      <div className="main-content">
        <h1 className="page-title ">WHO ELSE WOULD YOU LIKE TO COVER?</h1>
        <h2 className="title-sm text-danger text-center">
          {index >= 10 ? "MAXIMUM PEOPLE COVER ADDED" : "COVER UP TO 11 PEOPLE"}
        </h2>

        <div className="cover-section">
          <Row className="g-4">
            <Col xs="6">
              <Button
                disabled={index >= 10}
                variant="link"
                className="btn-cover"
                href={"/extended-family-cover?cover=spouse&index=" + index}
              >
                <div className="cust-radio-card">
                  <div className="cust-radio-container">
                    <div className="cust-radio-title">
                      Spouse / Life Partner
                    </div>
                    <img src="/images/Pic_7.svg"
                      alt="Spouse"
                      width={60}
                      height={60}
                      className="img-fluid mx-auto" />
                    {/* <ImgCompo
                      src="/images/Pic_7.svg"
                      alt="Spouse"
                      width={60}
                      height={60}
                      className="img-fluid mx-auto"
                    /> */}
                  </div>
                  <div className="cust-radio-badge">+ ADD</div>
                </div>
              </Button>
            </Col>
            <Col xs="6">
              <Button
                disabled={index >= 10}
                variant="link"
                className="btn-cover"
                href={"/extended-family-cover?cover=ownChildren&index=" + index}
              >
                <div className="cust-radio-card">
                  <div className="cust-radio-container">
                    <div className="cust-radio-title">
                      Own Children Under 21
                    </div>
                    <img src="/images/Pic_6.svg"
                      alt="Own"
                      width={60}
                      height={60}
                      className="img-fluid mx-auto" />
                    {/* <ImgCompo
                      src="/images/Pic_6.svg"
                      alt="Own"
                      width={60}
                      height={60}
                      className="img-fluid mx-auto"
                    /> */}
                  </div>
                  <div className="cust-radio-badge">+ ADD</div>
                </div>
              </Button>
            </Col>
            <Col xs="6">
              <Button
                disabled={index >= 10}
                variant="link"
                className="btn-cover"
                href={"/extended-family-cover?cover=parents&index=" + index}
              >
                <div className="cust-radio-card">
                  <div className="cust-radio-container">
                    <div className="cust-radio-title">
                      Parents / Grandparents
                    </div>
                    <img src="/images/Pic_2.svg"
                      alt="Parents"
                      width={60}
                      height={60}
                      className="img-fluid  mx-auto" />
                    {/* <ImgCompo
                      src="/images/Pic_2.svg"
                      alt="Parents"
                      width={60}
                      height={60}
                      className="img-fluid  mx-auto"
                    /> */}
                  </div>
                  <div className="cust-radio-badge">+ ADD</div>
                </div>
              </Button>
            </Col>
            <Col xs="6">
              <Button
                disabled={index >= 10}
                variant="link"
                className="btn-cover"
                href={
                  "/extended-family-cover?cover=formerSpouse&index=" + index
                }
              >
                <div className="cust-radio-card">
                  <div className="cust-radio-container">
                    <div className="cust-radio-title">
                      Former Spouse/ Parent of my Child
                    </div>
                    <img src="/images/Pic_4.svg"
                      alt="Former Spouse"
                      width={60}
                      height={60}
                      className="img-fluid mx-auto" />
                    {/* <ImgCompo
                      src="/images/Pic_4.svg"
                      alt="Former Spouse"
                      width={60}
                      height={60}
                      className="img-fluid mx-auto"
                    /> */}
                  </div>
                  <div className="cust-radio-badge">+ ADD</div>
                </div>
              </Button>
            </Col>
            <Col xs="6">
              <Button
                disabled={index >= 10}
                variant="link"
                className="btn-cover"
                href={"/extended-family-cover?cover=widerFamily&index=" + index}
              >
                <div className="cust-radio-card">
                  <div className="cust-radio-container">
                    <div className="cust-radio-title">Wider Family</div>
                    <img src="/images/Pic_3.svg"
                      alt="Wider Family"
                      width={60}
                      height={60}
                      className="img-fluid  mx-auto" />
                    {/* <ImgCompo
                      src="/images/Pic_3.svg"
                      alt="Wider Family"
                      width={60}
                      height={60}
                      className="img-fluid  mx-auto"
                    /> */}
                  </div>
                  <div className="cust-radio-badge">+ ADD</div>
                </div>
              </Button>
            </Col>
            <Col xs="6">
              <Button
                variant="link"
                className="btn-cover"
                href="/quote-summary"
              >
                <div className="cust-radio-card">
                  <div className="cust-radio-container">
                    <div className="cust-radio-title">
                      Not Covering Anyone Else
                    </div>
                  </div>

                  <div className="cust-radio-badge">Skip</div>
                </div>
              </Button>
            </Col>
          </Row>
        </div>

        <Footer>
          <ul className="btn-list">
            <li className="back-li">
              <BtnCompo
                buttonAction={"/select-cover"}
                buttonIcon={"/images/icon-back.png"}
                buttonClass={"btn-back"}
                buttonIconHeight={22}
                buttonIconWidth={27}
              />
            </li>
            <li>
              <BtnCompo
                buttonAction={"/rather-call-me"}
                buttonText={"RATHER CALL ME"}
                buttonOutline={true}
                buttonClass={"btn-rather"}
              />
            </li>
            <li>
              <BtnCompo
                buttonAction={
                  index >= 10
                    ? "/quote-summary"
                    : "/extended-family-cover?cover=spouse&index=" + index
                }
                buttonText={"NEXT"}
              />
            </li>
          </ul>
        </Footer>
      </div>
    </Container>
  );
};

export default ExtendedFamilySelection;
