import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SelectCover = () => {
  const [params, setParams] = useState("");
  const [userDetails, setUserDetails] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('profile')) {
      setUserDetails(JSON.parse(localStorage.getItem('profile')));
    } else { navigate("/profile"); }
  }, [navigate])

  return (
    <Container className="h-100">
      <div className="main-content">
        <h1 className="page-title mb-5">
          {userDetails ? `${userDetails.rankTitle.toUpperCase()} ${userDetails.fullNames.toUpperCase()} ${userDetails.surname.toUpperCase()} WHO WOULD YOU LIKE TO COVER?` : ''}
        </h1>
        <div className="cust-radio">
          <label className="cust-radio-content ">
            <input type="radio" id="cover1" name="cover" value="Myself" />
            <div className="cust-radio-card" onClick={() => { setParams('mySelf'); navigate("/my-cover?selectedCover=mySelf") }}>
              <div className="cust-radio-title">Myself</div>
              <img
                src="/images/Pic_1.svg"
                alt="Myself"
                width={80}
                height={80}
                className="img-fluid mb-4 mx-auto" />
              {/* <ImgCompo
                src="/images/Pic_1.svg"
                alt="Myself"
                width={80}
                height={80}
                className="img-fluid mb-4 mx-auto"
              /> */}
              <div className="cust-radio-badge">COVER</div>
            </div>
          </label>
          <label className="cust-radio-content ">
            <input
              type="radio"
              id="cover2"
              name="cover"
              value="Myself & My Family"
            />
            <div className="cust-radio-card" onClick={() => { setParams('family'); navigate("/my-cover?selectedCover=family") }}>
              <div className="cust-radio-title">Myself & My Family</div>
              <img src="/images/Pic_5.svg"
                alt="Myself & My Family"
                width={80}
                height={80}
                className="img-fluid mb-4 mx-auto" />
              {/* <ImgCompo
                src="/images/Pic_5.svg"
                alt="Myself & My Family"
                width={80}
                height={80}
                className="img-fluid mb-4 mx-auto"
              /> */}
              <div className="cust-radio-badge">COVER</div>
            </div>
          </label>
        </div>
        <Footer>
          <ul className="btn-list">
            <li className="back-li">
              <BtnCompo
                buttonAction={"/profile"}
                buttonIcon={"/images/icon-back.png"}
                buttonClass={"btn-back"}
                buttonIconHeight={22}
                buttonIconWidth={27}
              />
            </li>
            <li>
              <BtnCompo
                buttonAction={"/rather-call-me"}
                buttonText={"RATHER CALL ME"}
                buttonOutline={true}
                buttonClass={"btn-rather"}
              />
            </li>
            <li>
              <BtnCompo
                buttonAction={params ? `/my-cover?selectedCover=${params}` : '/my-cover?selectedCover=mySelf'}
                buttonText={"NEXT"} />
            </li>
          </ul>
        </Footer>
      </div>
    </Container>
  );
};

export default SelectCover;
