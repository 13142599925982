import ImgCompo from "components/img-compo";
import React from "react";
import { Container } from "react-bootstrap";

const Decline = () => {
  return (
    <Container className="h-100">
      <div className="main-content text-center">
        <h1 className="page-title m-lg-b">SORRY TO SEE YOU GO</h1>
        <div className="content-block">
          <div className="content-text">
            <p>
              By declining this consent, we are unable to continue with your
              application process. If you require further information please
              call us.
            </p>
            <p>
              <a href="tel:+086 177 7353" className="btn btn-primary mt-4">
                086 177 7353
              </a>
            </p>
          </div>
          <div className="content-logo">
            <ImgCompo
              src="/images/logo.png"
              alt="Shield Life Funeral"
              width={224}
              height={224}
              className="mt-auto"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Decline;
