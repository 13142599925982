import React, { useState } from "react";
import styles from "./slider.module.scss";
import RangeSlider from "react-bootstrap-range-slider";
import { Col, Row } from "react-bootstrap";
const Slider = ({ min = 0, max = 100, title, ...children }) => {
  const [value, setValue] = useState(0);
  return (
    <div>
      <Row className="justify-content-between">
        <Col className={styles.minMaxLabel} xs="auto">
          {min}
        </Col>
        <Col className={styles.minMaxLabel} xs="auto">
          {max}
        </Col>
      </Row>

      <RangeSlider
        value={value}
        tooltipPlacement="top"
        tooltip="on"
        onChange={(changeEvent) => setValue(changeEvent.target.value)}
        min={min}
        max={max}
        size={"sm"}
        title={title}
        {...children}
      />
    </div>
  );
};

export default Slider;
