import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import React from "react";
import { Container } from "react-bootstrap";

const TearmsCondition = () => {
  return (
    <Container>
      <div className="text-center">
        <h1 className="page-title-alt mb-5">
          Just before we continue, please read and accept the POPIA disclaimer
        </h1>
        <div className="text-sm">
          <p>
            In South Africa, the Protection of Personal Information Act 4 of 2013 (PoPIA) has been enacted to regulate the processing of Personal Information. When entering into this policy you provide us with information that may be protected by legislation including but not limited to PoPIA. We will take all reasonable steps to protect the information you provide us securely and confidentially. Please read more about how we use and protect your personal information in our Privacy Notice and PAIA manual available on our website https://www.shieldlife.co.za. By Opting to continue you, give consent to the  Processing of your Personal Information in terms of POPIA for all purposes of maintaining this insurance product and consent to the terms of the Privacy Notice and the PAIA manual located at https://www.shieldlife.co.za.
          </p>
          <p>
            I understand that the supply of this information is mandatory and without the supply of the information this agreement will be invalid. Shield Life will process and protect my personal information in terms of the required aspects of POPIA. I hereby give consent that Shield Life may request further processing of my personal information from a third party (Premium collection agency, Credit bureau etc.) for the purposes of maintaining my insurance product. I hereby agree that I have the appropriate authorization of supplying special / sensitive / personal information of data subjects other than myself. I hereby agree that all the information supplied in this agreement is accurate and complete and should any of this information change I will contact Shield Life Ltd to update my personal information. I, understand that without the above consent my policy will not be processed and the Insurer will not be liable for any claim that may arise.
          </p>
        </div>
      </div>
      <Footer>
        <ul className="btn-list">
          <li className="back-li">
            <BtnCompo
              buttonAction={"/"}
              buttonIcon={"/images/icon-back.png"}
              buttonClass={"btn-back"}
              buttonIconHeight={22}
              buttonIconWidth={27}
            />
          </li>
          <li>
            <BtnCompo
              buttonAction={"/decline"}
              buttonText={"DECLINE"}
              buttonOutline={true}
            />
          </li>
          <li>
            <BtnCompo buttonAction={"/profile"} buttonText={"ACCEPT"} />
          </li>
        </ul>
      </Footer>
    </Container>
  );
};

export default TearmsCondition;
