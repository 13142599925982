import React, { useState } from "react";
import { Image } from "react-bootstrap";
import ConvertImage from "react-convert-image";

const ImgCompo = ({ src, ...chilrdern }) => {
  const [imgPath, setImgPath] = useState();
  const handleConvertedImage = (url) => {
    setImgPath(url);
  };

  return (
    <>
      <div className="d-none">
        <ConvertImage image={src} onConversion={handleConvertedImage} />
      </div>
      <Image src={imgPath} {...chilrdern} />
    </>
  );
};

export default ImgCompo;
